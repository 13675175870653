import React, { FC, useCallback, useState } from 'react';
import { Link } from 'gatsby';

import { getProductDescription } from 'utils/helpers';
import NFAccordion from 'components/common/NFAccordion';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';

import { IChildrenDosageComponentProps } from './model';

import './ChildrenDosage.scss';

const ChildrenDosage: FC<IChildrenDosageComponentProps> = ({
  accordionHeader,
  table,
  product,
  openedAccordion,
  accordionIndex,
  setOpenedAccordionIndex,
}) => {
  const colProduct = product?.[0];
  const cardImg = colProduct?.defaultProductImage?.gatsbyImage?.childImageSharp;

  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(-1);

  const onChangeCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
    setOpenedAccordionIndex(accordionIndex);
  }, []);

  return (
    <div className="nf-children-dosage">
      <Link to={colProduct?.link} aria-label={colProduct?.defaultProductTitle}>
        <div className="nf-children-dosage__head">
          <div className="nf-children-dosage__product-img">
            {cardImg ? (
              <GatsbyImage
                alt={colProduct?.productImageAlt}
                fluid={cardImg?.fluid}
                objectFitData={{ objectFit: 'contain' }}
                imgStyle={{ top: 'auto', bottom: '0' }}
              />
            ) : null}
          </div>
          <div className="nf-children-dosage__product-description">
            <strong className="nf-children-dosage__product-title">
              {colProduct?.defaultProductTitle}
            </strong>
            <span className="nf-children-dosage__product-description-text">
              {getProductDescription(colProduct?.descriptionLong, colProduct?.descriptionShort)}
            </span>
          </div>
        </div>
      </Link>
      <NFAccordion
        onChange={onChangeCallback}
        changeOnClick={false}
        items={[
          {
            header: accordionHeader,
            content: <DangerouslySetInnerHtml html={table} />,
          },
        ]}
        selectedIndex={openedAccordion ? selectedAccordionIndex : -1}
      />
    </div>
  );
};

export default ChildrenDosage;
