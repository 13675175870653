import React, { FC, useMemo, useState, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { range, evenIndexes, oddIndexes } from 'utils/helpers';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import ChildrenDosage from 'components/ChildrenDosage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { parseBanner } from 'components/Banner/parsers';
import { gtmService } from 'services/gtmService';

import './children-dosage-information.scss';

interface IChildrenDosagePage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: ChildrenDosageTypes.IChildrenDosageData;
}

const ChildrenDosagePage: FC<IChildrenDosagePage> = ({ pageContext: { breadcrumbs }, data }) => {
  const {
    childrenDosage: {
      banner,
      leadText,
      childrenDosageCols,
      seoMetaKeywords,
      seoMetaDescription,
      seoMetaTitle,
      seoExternalHreflangs,
      ogImage,
      sectionDosageBgColor,
      dosageTableRowOddBg,
      dosageTableRowEvenBg,
      lang,
      pageName,
    },
    childrenDosageColsProducts,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = data;

  const { isMobile } = useScreenRecognition();

  const sectionBgColor = sectionDosageBgColor?.[0]?.properties?.colorPicker?.label
    ? `${sectionDosageBgColor[0].properties.colorPicker.label}-default-bg`
    : '';

  const tableRowOddBg = dosageTableRowOddBg?.[0]?.properties?.colorPicker?.label
    ? `${dosageTableRowOddBg[0].properties.colorPicker.label}-default-bg`
    : '';

  const tableRowEvenBg = dosageTableRowEvenBg?.[0]?.properties?.colorPicker?.label
    ? `${dosageTableRowEvenBg[0].properties.colorPicker.label}-default-bg`
    : '';

  const borderTableColor =
    dosageTableRowEvenBg?.[0]?.properties?.colorPicker?.label === ('white' || 'grey')
      ? dosageTableRowOddBg?.[0]?.properties?.colorPicker?.label
      : dosageTableRowEvenBg?.[0]?.properties?.colorPicker?.label;

  const [openedAccordionIndex, setOpenedAccordionIndex] = useState<number>();

  const classesBg = classNames('nf-children-dosage-page__bg-holder', {
    [sectionBgColor]: sectionBgColor,
    [`${tableRowOddBg}-table-row-odd`]: tableRowOddBg,
    [`${tableRowEvenBg}-table-row-even`]: tableRowEvenBg,
    [`${borderTableColor}-child-text`]: borderTableColor,
  });

  const childrenDosageColumns = useMemo(() => {
    const childrenDosageLength = childrenDosageCols.length;
    const columns = isMobile
      ? [range(childrenDosageLength)]
      : [oddIndexes(childrenDosageLength), evenIndexes(childrenDosageLength)];

    return columns;
  }, [isMobile, childrenDosageCols]);

  useEffect(() => {
    const timeoutId = gtmService.emitProductListingView(
      pageName,
      childrenDosageColsProducts?.nodes
    );

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <section className="nf-children-dosage-page">
        <Banner {...parseBanner(banner[0].properties)} breadcrumbs={breadcrumbs} />
        <div className={classesBg}>
          {leadText && leadText.length ? (
            <Container fluid>
              <Row>
                <Col sm={12} lg={{ span: 8, offset: 2 }}>
                  <DangerouslySetInnerHtml
                    className="nf-children-dosage-page__lead-text"
                    html={leadText}
                  />
                </Col>
              </Row>
            </Container>
          ) : null}
          <div className="nf-children-dosage-holder">
            {childrenDosageColumns?.map((column) => (
              <div className="nf-children-dosage-column">
                {column.map((index) => (
                  <div className="nf-children-dosage-holder__col" key={`dosageItem${index}`}>
                    <ChildrenDosage
                      accordionHeader={childrenDosageCols[index].properties.accordionHeader}
                      table={childrenDosageCols[index].properties.accordionDosageSlide}
                      product={childrenDosageColsProducts.nodes.filter(
                        (product) =>
                          product.link === childrenDosageCols[index].properties.productLink[0].url
                      )}
                      accordionIndex={index}
                      openedAccordion={openedAccordionIndex === index}
                      setOpenedAccordionIndex={setOpenedAccordionIndex}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $childrenDosageColsLinks: [String]
    $pageBannerImageSize: Int = 560
    $pageIdRegex: String
  ) {
    childrenDosage(lang: { eq: $lang }) {
      ...FragmentSeo
      banner {
        properties {
          ...FragmentBanner
        }
      }
      lang
      pageName
      leadText
      sectionDosageBgColor {
        ...FragmentColorProps
      }
      dosageTableRowOddBg {
        ...FragmentColorProps
      }
      dosageTableRowEvenBg {
        ...FragmentColorProps
      }
      childrenDosageCols {
        structure
        properties {
          accordionDosageSlide
          accordionHeader
          isOpen
          productDescriptionList
          productImg {
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 165) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imgAlt
          productSubTitle
          productTitle
          productLink {
            url
          }
        }
      }
    }
    childrenDosageColsProducts: allUmbracoProduct(
      filter: { link: { in: $childrenDosageColsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    pageLangs: allChildrenDosage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default ChildrenDosagePage;
